import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import PresTrackedContainer from '../../../common/components/PresTrackedContainer';
import { withLayoutContext } from '../../../common/context/LayoutContext';

const SliderItemRows = (props) => {
  return (
    <SwiperSlide className={`slider-item-rows size-${props.size}`} key={props.key}>
      {React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
          size: props.size
        });
      })}
    </SwiperSlide>
  );
};

class Row extends Component {
  static propTypes = {
    allowTouchMove: PropTypes.bool,
    autoplay: PropTypes.array,
    autoplaySpeed: PropTypes.object,
    columnsInRow: PropTypes.number,
    loop: PropTypes.bool,
    numOfItemRows: PropTypes.number.isRequired,
    rowNum: PropTypes.number,
    swiperModules: PropTypes.object,
    totalItems: PropTypes.number,
    viewColumnsInRow: PropTypes.number
  };

  static contextTypes = {
    focusManager: PropTypes.object
  };

  static childContextTypes = {
    rowNum: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRowFocus = this.handleRowFocus.bind(this);

    this.sliderRef = React.createRef();
    this.rowRef = React.createRef();

    this.state = {
      isPopOpen: false,
      lowestVisibleItemIndex: 0
    };
  }

  componentDidMount() {
    if (this.context.focusManager) {
      const el = ReactDOM.findDOMNode(this.rowRef.current);
      if (el) {
        this.context.focusManager.collectFocasableElements(el);
      }
    }
  }

  componentWillUnmount() {
    if (this.popAnimationId) {
      clearTimeout(this.popAnimationId);
      delete this.popAnimationId;
    }
  }

  getChildContext() {
    return { rowNum: this.props.rowNum };
  }

  handleMouseEnter(e) {
    this.isMouse = true;
  }

  handleMouseLeave(e) {
    this.isMouse = false;
  }

  handleMouseMove(e) {
    this.isMouse = true;
    this.setState({ keyboardFocus: false });
  }

  handleRowFocus(event) {
    if (this.isMouse) return;
    if (event.target.classList.contains('sliderRefocus')) {
      this.setState({ keyboardFocus: true });
    }
  }

  handleKeyDown(event) {
    // Tab
    if (event.which === 9 && !event.shiftKey) {
      if (event.target.classList.contains('handleNext')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('div[tabindex="0"]');
        const index = _.findIndex(links, (link) => {
          return link.id === event.target.id;
        });
        if (links.length == index + 1) {
          const arrow = event.currentTarget.querySelector('.handleNext');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      if (event.target.classList.contains('handlePrev')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('div[tabindex="0"]');
        const index = _.findIndex(links, (link) => {
          return link.id === event.target.id;
        });
        if (index === 0) {
          const arrow = event.currentTarget.querySelector('.handlePrev');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }
    }
  }

  render() {
    const settings = {
      allowTouchMove: typeof this.props.allowTouchMove === 'boolean' ? 
        this.props.allowTouchMove : true,
      autoplay: this.props.autoplay || {},
      loop: typeof this.props.loop === 'boolean' ? 
        this.props.loop : true,
      modules: this.props.swiperModules || [],
      speed: this.props.speed || 1000
    };

    if (
      this.props.columnsInRow > 2 &&
      this.props.numOfItemRows !== 1 &&
      (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
    ) {
      const rowCount = this.props.catchLayout === 'image' ? 3 : 5;
      totalItems = Math.ceil((this.props.totalItems + rowCount) / this.props.numOfItemRows);
    }

    let columnsInRow = this.props.columnsInRow;

    if (this.props.viewColumnsInRow) {
      columnsInRow = this.props.viewColumnsInRow;
    }

    return (
      <div className="row" data-column-items={columnsInRow} ref={this.rowRef}>
        <PresTrackedContainer>
          {this.columnsInRow !== 0 ? (
            <div
              className={classnames('row-content', {
                'slider-hover-trigger-layer': columnsInRow !== 1,
                'keyboard-focus': this.state.keyboardFocus,
              })}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseMove={this.handleMouseMove}
              onFocus={this.handleRowFocus}
              onKeyDown={this.handleKeyDown}
            >
              <Swiper ref={this.sliderRef} {...settings}>
                {this.renderChildren()}
              </Swiper>
            </div>
          ) : (
            this.renderChildren()
          )}
        </PresTrackedContainer>
      </div>
    );
  }

  renderChildren() {
    let rowCount = 0;
    let childFrom;
    let childTo;

    const children = React.Children.map(this.props.children, (child, index) => {
      let sliderRefocus = true;
      if (
        this.props.columnsInRow > 2 &&
        (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
      ) {
        sliderRefocus = childFrom <= index && index <= childTo;
      }
      return React.cloneElement(<SwiperSlide>{child}</SwiperSlide>, {});
    });
    if (this.props.numOfItemRows === 1) {
      return children;
    }

    let _children = _.chunk(children, this.props.numOfItemRows);

    if (
      this.props.columnsInRow > 2 &&
      (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
    ) {
      if (this.props.catchLayout === 'image') {
        _children = _.flatten([_.first(children), null, _.chunk(_.tail(children), this.props.numOfItemRows)]);
      } else {
        _children = _.flatten([_.first(children), null, null, _.chunk(_.tail(children), this.props.numOfItemRows)]);
      }
    }
    return _.map(_children, (chunkedChildren, index) => {
      const props = {
        size: `1x${this.props.numOfItemRows}`,
        isHide: chunkedChildren === null,
      };
      if (
        this.props.columnsInRow > 2 &&
        (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
      ) {
        if (index === 0) {
          props.size = `${rowCount}x${this.props.numOfItemRows}`;
        } else if (index === 1 && rowCount === 3) {
          props.size = `${rowCount - 1}x${this.props.numOfItemRows}`;
        }
      }
      return (
        <SliderItemRows key={`slider_item_row_${this.props.rowNum}-${index}`} {...props}>
          {chunkedChildren}
        </SliderItemRows>
      );
    });
  }
}

const root = withLayoutContext(Row);

export default root;
