import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MainViewLink from './MainViewLink';
import routes from '../../common/routes';
import classnames from 'classnames';
import lineClamp from '../../../common/lineClamp';
import Row from '../../bluerose/components/browse/Row';
import { getGenres2Code, getArticle2ProviderCode } from '../../../common/getGenreCategories';
import { Autoplay } from 'swiper/modules';

class EmergencyContents extends React.Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.getUrgentNewsLinks = this.getUrgentNewsLinks.bind(this);
    this.getModuleLink = this.getModuleLink.bind(this);
  }

  // 号外モジュールのURLを生成
  getModuleLink(itemData) {
    if (_.get(itemData, 'link_url')) {
      return _.get(itemData, 'link_url');
    }
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false);
    const providerCode = isEachCompany ? getArticle2ProviderCode(itemData) : 'ntv';
    const categoryCode = getGenres2Code(
      _.get(itemData, 'genres'),
      _.get(this.context, 'models.config.data'),
      providerCode,
    );
    if (categoryCode) {
      return routes.articleDetail.makePath({
        categoryCode,
        id: _.get(itemData, 'article_id'),
      });
    } else {
      return routes.articles.makePath({
        id: _.get(itemData, 'article_id'),
      });
    }
  }

  // 号外モジュールリンク生成
  getUrgentNewsLinks(linksData = []) {
    if (_.isEmpty(linksData)) {
      return [];
    }

    return _.compact(
      _.map(linksData, (itemData, index) => {
        if (!itemData || !itemData.title) {
          return null;
        }

        const title = _.get(itemData, 'title');
        const content = _.get(itemData, 'content');
        const url = this.getModuleLink(itemData);

        if (!url) {
          return null;
        }

        return (
          <MainViewLink href={url} className="emergency-list-block" key={`emergency-list-${index}`}>
            <div className="emergency-block">
              <div
                className={classnames('tag', {
                  'urgent-news-color': title === '速報',
                  'live-color': title.toLowerCase() === 'live',
                  'notification-color': title === 'お知らせ',
                })}
              >
                {title}
              </div>
              <div className="emergency-title">{lineClamp(content, 40)}</div>
            </div>
          </MainViewLink>
        );
      }),
    );
  }

  render() {
    const { urgentNewsLink, invisibleAd } = this.props;
    const urgentNewsLinks = this.getUrgentNewsLinks(urgentNewsLink);
    const isSpMode = _.get(this.context, 'spMode');
    const classNeme = isSpMode ? 'sp-emergency-contents' : 'pc-emergency-contents';

    if (_.isEmpty(urgentNewsLinks)) {
      return null;
    }

    return (
      <div className={`emergency-contents ${classNeme}`} key={classNeme}>
        <Row
          key="emergency-contents-row"
          viewColumnsInRow={1}
          rowNum={1}
          totalItems={urgentNewsLinks.length}
          allowTouchMove={false}
          autoplay={{ delay: 5000 }}
          loop={true}
          speed={2000}
          swiperModules={[Autoplay]}
        >
          {urgentNewsLinks}
        </Row>
      </div>
    );
  }
}

export default EmergencyContents;
